import React, { useState, useEffect } from 'react';

const menuItems = [
  { id: 'about-us', label: 'om oss', title: 'Om oss', path: '#about-us', activeId: 'section-about-us' },
  { id: 'services', label: 'tjänster', title: 'Tjänster', path: '#services', activeId: 'section-services' },
  { id: 'career', label: 'karriär', title: 'Karriär', path: '#career', activeId: 'section-career' },
  { id: 'contact', label: 'kontakt', title: 'Kontakt', path: '#contact', activeId: 'section-contact' },
];

const isBrowser = typeof window !== `undefined`;

/* initial values for doc & gatsby build purposes */
const AppContext = React.createContext([{
  activeMenuItem: '',
  handleActiveMenuItem: () => {},
  sectionTitle: '',
  menuItems: [],
  menuOpen: false,
  navigate: () => {},
  toggleMenu: () => {},
  reduceMotion: false,
}, () => {}]);

const preventDefault = (e) => {
  const event = e || window.event;
  event.preventDefault();
}

const navigate = id => {
  const elem = document.querySelector(`#${id}`);
  const bodyRectTop = document.body.getBoundingClientRect().top;
  const elemRectTop = elem.getBoundingClientRect().top;

  window.scrollTo({
    top: (elemRectTop - bodyRectTop) - 70,  // subtract header hight
    behavior: 'smooth',
  });
};


const AppStateProvider = (props) => {
  const toggleMenu = () => {
    setState((state) => ({ ...state, menuOpen: !state.menuOpen }));
  };

  const onReducedMotionChange = () => {
    setState((state) => ({ ...state, reduceMotion: true }));
  };

  const handleActiveMenuItem = id => {
    const item = menuItems.find(i => i.activeId === id);
    const activeEl = document.activeElement;
    if (activeEl.classList.contains('span-link')) {
      activeEl.blur();
    }
    setState(state => ({ ...state, activeMenuItem: id, sectionTitle: item.title }));
  }
  /* set the actual initial state values here to create application initial state */
  const [state, setState] = useState({
    activeMenuItem: '',
    handleActiveMenuItem,
    sectionTitle: 'Hem',
    menuItems,
    navigate,
    toggleMenu,
    menuOpen: false,
    reduceMotion: false,
  });

  useEffect(() => {
    /* disable events in body while menu is open */
    document.documentElement.style.overflowY = state.menuOpen ? 'hidden' : 'scroll';
    const footerLinks = document.querySelectorAll('.footer a');
    footerLinks.forEach(link => {
      link.tabIndex = state.menuOpen ? -1 : 0
    });
    if (state.menuOpen) {
      document.addEventListener('touchmove', preventDefault, { passive: false });
    } else {
      document.removeEventListener('touchmove', preventDefault, { passive: false });
    }
  }, [state.menuOpen]);

  useEffect(() => {
    /* Listen for accessibility changes */
    const mediaQuery = isBrowser
      ? window.matchMedia('(prefers-reduced-motion: reduce)')
      : null;

    if (mediaQuery && mediaQuery.matches) {
      mediaQuery.addEventListener('change', onReducedMotionChange, { passive: false });
    }
    return () => {
      mediaQuery.removeEventListener('change', onReducedMotionChange, { passive: false });
    }
  }, []);

  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  );
}

export { AppContext, AppStateProvider };
