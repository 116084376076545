/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { AppStateProvider } from './src/context/AppContext';
import smoothscroll from 'smoothscroll-polyfill';

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
  // package will check if polyfill is necessary
  smoothscroll.polyfill();

  // attempted polyfill for forEach on NodeList for IE11
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
};

 export const wrapRootElement = ({ element }) => (
   <ParallaxProvider>
     <AppStateProvider>
      { element }
     </AppStateProvider>
   </ParallaxProvider>
 );

 export const onInitialClientRender = () => {
   window.scrollTo(0, 0);
 };
